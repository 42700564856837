/**
 * Defines the main color of the shop theme. Usage: primary elements' background and color.
 */
/**
 * Defines alternative color of the shop theme. Usage: secondary elements' background and color.
 */
/**
 * Defines white color. Usage: not transparent elements' background of default background.
 */
/**
 * Defines a light shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines a lighter shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines the lightest shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines the shade of the black color. Usage: as a base of black color for semi-transparent elements.
 */
/**
 * Defines a dark shade color. Usage: elements' text color variant.
 */
/**
 * Defines a darker shade color. Usage: elements' text color variant.
 */
/**
 * Defines the darkest shade color. Usage: elements' text color variant.
 */
/**
 * Defines a transparent color. Usage: elements' background, border.
 */
/**
 * Defines the main background color. Usage: body and side-drawer background.
 */
/**
 * Defines the main color of text. Usage: default global text color.
 */
/**
 * Defines a default global border color for the input elements.
 */
/**
 * Defines a default global border color for the input elements on focus event.
 */
/**
 * Defines a shadow shade and transparency for the shadowed elements.
 */
/**
 * Defines a default overlay color. Usage: elements with overlay - side-drawer, popups etc.
 */
/**
 * Defines a default image overlay color. Usage: images with overlay - lazy-image.
 */
/**
 * Defines action colors map.
 */
/**
 * Defines font family setting. Usage: body global font styles.
 */
/**
 * Defines font family setting for code. Can be used for code and pre elements.
 */
/**
 * Defines basic font size. Usage: global basic font size.
 */
/**
 * Font size values map. Usage: mixins providing styles for headings, text elements etc.
 */
/**
 * Font weight values map. Usage: mixin to set font weight property.
 */
/**
 * Font line height values map. Usage: mixins providing styles for headings, text elements etc.
 */
/**
 * Defines a default duration value for the animation effects. Usage: mixins providing animation properties.
 */
/**
 * Defines a default timing function for the animation effects. Usage: mixins providing animation properties.
 */
/**
 * Defines default z-index value.
 */
/**
 * Defines z-index value for ajax dynamic loader.
 */
/**
 * Defines z-index value for the floating elements.
 */
/**
 * Defines z-index value for the header context.
 */
/**
 * Defines z-index value for the side drawer context.
 */
/**
 * Defines z-index value for the popup.
 */
/**
 * Defines z-index value for the overlay.
 */
/**
 * Defines z-index value for the notification area context.
 */
/**
 * Defines z-index value for the search suggestions context.
 */
/**
 * Defines z-index value for the ajax loader.
 */
/**
 * Defines z-index value for the top navigation context.
 */
/**
 * Defines spacing values map. Usage: spacing helpers and mixins.
 */
/**
 * Defines breakpoints' values for responsive.
 */
/**
 * Defines grid system columns count.
 */
/**
 * Defines grid container max width.
 */
/**
 * Defines gutters' size for grid "with-gutter" modifier.
 */
/**
 * Defines import blacklist. Usage: import helper.
 */
/**
 * Provides list of properies for reset usage.
 */
/**
 * Wrapper function for the darken sass function with a default degree parameter.
 * @param {Color} $setting-color
 * @param {Number} $degree [10]
 */
/**
 * Wrapper function for the lighten sass function with a default degree parameter.
 * @param {Color} $setting-color
 * @param {Number} $degree [10]
 */
/**
 * Sets font-size property from font size settings map - $setting-font-size according to the passed map key.
 * @param {String | Number} $size
 */
/**
 * Sets font-weight property from font weight settings map - $setting-font-weight according to the passed map key.
 * @param {String} $weight
 */
/**
 * Sets line-height property from line-height settings map - $setting-font-line-height according to the passed map key.
 * @param {String | Number} $height
 */
/**
 * Povides transition property configured by the passed parameters.
 * @param {String} $props
 * @param {Number} $duration [$setting-effect-default-duration]
 * @param {String} $ease [$setting-effect-default-ease]
 */
/**
 * Forces element visibility.
 */
/**
 * Forces element invisibility.
 */
/**
 * Provides global class names with spacing properties set from $setting-spacing map.
 * @param {String} $name Main selector name.
 * @param {List} $sides [[]] List of sides to set properties.
 */
/**
 * Returns 0 or the first value from the $edges list.
 * @param {List} $edges [$setting-breakpoints] List length must be less than or equal to 2.
 * @return {Base}
 */
/**
 * Returns null or the last value from the $edges list.
 * @param {List} $edges [$setting-breakpoints] List length must be less than or equal to 2.
 * @return {Base}
 */
/**
 * Sets screen media query with min-width set to $min parameter.
 * @param $min {Number} [0]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with breakpoints range passed as parameters.
 * @param {Number} $min [0]
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with max-width set to $max parameter.
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with breakpoints range passed to parameters or
 * with min-width query if only one parameter is passed.
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Returns column ratio in relation to columns count.
 * @param {Number} $column
 * @param {Number} $columns [$setting-grid-columns]
 * @return {Number}
 */
/**
 * Adds clearing for element with floated child elements.
 */
/**
 * Adds a box-shadow with basic values.
 */
/**
 * Checks parameter on presence in the blacklist of imports setting list.
 * @param {List} $keywords
 * @content Extends mixin with properties if $keywords is not on the blacklist.
 */
.center {
  text-align: center;
}

.pt20 {
  padding-top: 1.25rem;
}

@media print {
  .print-hidden {
    display: none;
  }
}

.print-visible {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
@media print {
  .print-visible {
    visibility: visible;
    height: auto;
    overflow: auto;
  }
}

@media print {
  .col--print-1 {
    width: calc((100% / 12) * 1);
    float: left;
  }
  .col--print-2 {
    width: calc((100% / 12) * 2);
    float: left;
  }
  .col--print-3 {
    width: calc((100% / 12) * 3);
    float: left;
  }
  .col--print-4 {
    width: calc((100% / 12) * 4);
    float: left;
  }
  .col--print-5 {
    width: calc((100% / 12) * 5);
    float: left;
  }
  .col--print-6 {
    width: calc((100% / 12) * 6);
    float: left;
  }
  .col--print-7 {
    width: calc((100% / 12) * 7);
    float: left;
  }
  .col--print-8 {
    width: calc((100% / 12) * 8);
    float: left;
  }
  .col--print-9 {
    width: calc((100% / 12) * 9);
    float: left;
  }
  .col--print-10 {
    width: calc((100% / 12) * 10);
    float: left;
  }
  .col--print-11 {
    width: calc((100% / 12) * 11);
    float: left;
  }
  .col--print-12 {
    width: calc((100% / 12) * 12);
    float: left;
  }

  .usercentrics-button, .slick-arrow, .product-compare__item-remove {
    display: none;
  }
}
@media print {
  .order-detail-rma .header {
    display: none;
  }
}

/**
 * Adds a small arrow on the element for the configured direction.
 */
/**
 * Defines import blacklist. Usage: import helper.
 */
/**
 * @deprecated Use agent-widget-agent-navigation mixin instead.
 */
/**
 * @deprecated Use agent-widget-agent-control-bar mixin instead.
 */
.summary-overview__grand-total-price {
  font-size: 1.1rem;
  line-height: 1.4;
}

/**
 * @deprecated Use cms-search-page-sort mixin instead.
 */
/**
 * @deprecated Use company-page-business-unit-chart-item mixin instead.
 */
/**
 * @deprecated Use company-user-agent-widget-company-user-list mixin instead.
 */
/**
 * @deprecated Use file-manager-widget-file-download-link mixin instead.
 */
/**
 * @deprecated Use price-product-volume-widget-volume-price mixin instead.
 */
/**
 * @deprecated Use price-product-volume-widget-volume-price-table mixin instead.
 */
/**
 * @deprecated Use product-option-widget-shopping-list-product-option-list mixin instead.
 */
/**
 * @deprecated Use product-packaging-unit-widget-packaging-unit-cart mixin instead.
 */
/**
 * @deprecated Use product-review-widget-rating-selector mixin instead.
 */
/**
 * @deprecated Use product-search-widget-product-quick-add-form mixin instead.
 */
/**
 * @deprecated Use configurable-bundle-widget-order-configured-bundle mixin instead.
 */
/**
 * @deprecated Use configurable-bundle-widget-order-configured-bundle-product mixin instead.
 */
.collapsible-list__button {
  background-color: rgba(255, 255, 255, 0);
  color: #E30613;
  cursor: pointer;
}
.collapsible-list__button:hover {
  text-decoration: underline;
}
.collapsible-list__button::after {
  content: attr(data-show);
}
.collapsible-list__button--shown::after {
  content: attr(data-hide);
}
.collapsible-list__button-content {
  display: none;
}

/* fe-sniffer:disabled correct-scss-component-structure */
/**
 * @deprecated Use shop-ui-quote-status-color mixin instead.
 */
/**
 * @deprecated Use tabs-widget-search-tabs mixin instead.
 */
.project-order-info {
  margin-bottom: 1.875rem;
}
.project-order-info__project-order--number {
  font-weight: 500;
}
.project-order-info__project-order--manufacturer {
  text-transform: uppercase;
}
.project-order-info__customer-reference--content {
  font-weight: 500;
}